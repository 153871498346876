import { useNavigate } from "react-router-dom";
import { ASSIST_MAIL, urlParams } from "../../constants/AppConstants";
import { failureNotificationWithBtn } from "./CommonNotifications";

export const httpsUrlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g;

export const SAMPLE_CSV_FILE_URL = "https://open-usage-bucket.s3.amazonaws.com/Sample_Sheet_.csv";

export const HOMEPAGE_URL = "/inbox";

export const FB_API_VERSION = "v21.0";
export const FB_SDK_URL = "https://connect.facebook.net/en_US/sdk.js";

export const PATTERN_FOR_MATCHING_CURLY_BRACED_VARIABLES = /(\{\{.*?\}\})/;

function capitalizeFirstLetter(str) {
  if (!str) return str;
  str = str?.toLowerCase();
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function getDefaultUnknownErrorMessageContent(prefix) {
  prefix = prefix ?? "Something went wrong!";
  return `${prefix} Please contact support ${ASSIST_MAIL}`;
}

function getRandomNumber(numberOfDigits) {
  if (numberOfDigits < 1) {
    throw new Error("Number of digits must be at least 1");
  }

  const upperBound = Math.pow(10, numberOfDigits) - 1;
  const lowerBound = Math.pow(10, numberOfDigits - 1);

  return Math.floor(Math.random() * (upperBound - lowerBound + 1)) + lowerBound;
}

function mapToMediaList(mediaFiles, hideCloseIcon) {
  return mediaFiles?.map((file) => ({
    name: file.name ?? "file",
    url: file.url,
    size: file.size ?? -1,
    contentType: file.contentType ?? "dummy/dummy",
    type: file.contentType ?? "dummy/dummy",
    thumbnailUrl: file.thumbnailUrl,
    status: "done",
    uid: getRandomNumber(3),
    hideCloseIcon: hideCloseIcon,
  }));
}

function checkWhetherServiceIsAuthorized(appProperties, integId, serviceObject, isLeft) {
  if (!serviceObject) {
    serviceObject = getServiceObjectFromIntegId(appProperties, integId);
  }
  if (isLeft) {
    return serviceObject?.left?.auth?.authorized;
  } else {
    return serviceObject?.right?.auth?.authorized;
  }
}

function getServiceObjectFromIntegId(appProperties, integId) {
  return appProperties?.installedApps?.find((obj) => obj?.integProps?.integId === integId);
}

function openServiceNotAuthorizedModal(serviceObject, appProperties) {
  const matchedPhoneAccess = appProperties?.licenseObj?.allLicensedUserDetails?.find(
    (user) => user?.remoteUserId === appProperties?.userId
  )?.phoneAccess;

  if (matchedPhoneAccess === 0) {
    let redirectionUrl = `/channels/textsms/${serviceObject?.service_name}?`;
    appProperties?.controller?.getUrlParamsForRedirection(true, appProperties).then(function (redirectionUrlFromApp) {
      if (redirectionUrlFromApp) {
        redirectionUrl += redirectionUrlFromApp;
      } else {
        redirectionUrl += urlParams;
      }
      failureNotificationWithBtn(
        "Service not authorized",
        `${serviceObject?.service_display_name} service is not authorized. Please proceed to authorize.`,
        "Authorize",
        redirectionUrl
      );
    });
  }
}

export const validateEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(email).toLowerCase());
};

function checkIsAnyAppIsAuthorized(appProperties) {
  return appProperties?.installedApps?.some(checkWhetherAnAppIsAuthorized);
}

function checkWhetherAnAppIsAuthorized(app) {
  return app?.right?.auth?.authorized;
}

function getAddTemplateUrl(appProperties, moduleName) {
  return new Promise((resolve, reject) => {
    appProperties?.controller?.getUrlParamsForRedirection(true, appProperties).then((redirectionUrl) => {
      if (moduleName) {
        resolve("/templates?" + redirectionUrl + "&resource=" + (moduleName ?? "") + "&openTemplate=true");
      } else {
        appProperties?.controller?.getAssociatedObjectType().then(function (moduleName) {
          resolve("/templates?" + redirectionUrl + "&resource=" + (moduleName ?? "") + "&openTemplate=true");
        });
      }
    });
  });
}

function openTemplateUrlInBlankPage(appProperties, moduleName) {
  getAddTemplateUrl(appProperties, moduleName).then((link) => {
    if (link) {
      window.open(link, "_blank");
    }
  });
}

const checkTruthy = (object) => {
  if (!object) {
    return false;
  }
  if (typeof object == "object") {
    return Object.keys(object).length > 0;
  }
};

const getRegexForFindingPlaceHolderWithDigitsOnly = () => {
  return /\{\{\d+\}\}/g;
};

const navigateToPathWithCurrentParams = (pathToNavigate, navigate) => {
  const urlParams = window.location.search;
  navigate(`${pathToNavigate}${urlParams}`);
};

export {
  capitalizeFirstLetter,
  checkIsAnyAppIsAuthorized,
  checkWhetherAnAppIsAuthorized,
  getDefaultUnknownErrorMessageContent,
  openServiceNotAuthorizedModal,
  checkWhetherServiceIsAuthorized,
  getServiceObjectFromIntegId,
  getRandomNumber,
  mapToMediaList,
  openTemplateUrlInBlankPage,
  getAddTemplateUrl,
  checkTruthy,
  getRegexForFindingPlaceHolderWithDigitsOnly,
  navigateToPathWithCurrentParams,
};
