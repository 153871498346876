import ".././PricingPage.css";
import React from "react";
import { Card, Col, Row, Tooltip } from "antd";
import { theme } from "antd";
import { Button, Modal, Layout, Typography } from "antd";
import { useState, useEffect } from "react";
import useHttp from "../hooks/useHttp";
import { useScript } from "./../hooks/Hooks";
import YearlyPricingTag from "../assets/gif/YearlyTag.gif";

const { Header, Content } = Layout;
const { Title, Text } = Typography;

function PricingPage() {
  const { token } = theme.useToken();
  const panelStyle = {
    borderTop: "2px solid #D7DEF0",
  };
  const [show, setShow] = useState(true);
  const [plan, setPlan] = useState([]);
  const [feature, setFeature] = useState([]);
  const [defaults, setDefaults] = useState([]);
  const [key, setKey] = useState(0);
  const [domainUrl, setDomainUrl] = useState("");
  const [successModal, setSuccessModal] = useState(false);
  const [expandedKey, setExpandedKey] = useState(null); // Track the expanded FAQ item

  let [isSuccessCheckout, setIsSuccessCheckout] = useState(false);
  const { fetchData } = useHttp();
  const queryString = window.location.search;
  const urlParamsObj = new URLSearchParams(queryString);
  var locationHref = window.location.origin;

  const onChange = (checked) => {
    setShow(checked);
  };
  const handleRefresh = () => {
    setKey((prevKey) => prevKey + 1);
  };
  const urlParams = new URLSearchParams(window.location.search);

  const handleClickDomainUrl = () => {
    const urlParamsInbox = JSON.parse(urlParams.get("urlParams"));
    delete urlParamsInbox.sideBar;
    delete urlParamsInbox.topBar;

    const queryString = new URLSearchParams(urlParamsInbox).toString();
    window.open("/settings/users?purchased=true&" + queryString, "_self");
  };

  const chargeBeeScript = useScript("https://js.chargebee.com/v2/chargebee.js");

  if (chargeBeeScript === "ready") {
    var siteAttribute = "oapps";
    if (
      locationHref.indexOf("jupiter.rekzyl.com") !== -1 ||
      locationHref.indexOf("client4.rekzyl.com") !== -1 ||
      locationHref.indexOf("client2.rekzyl.com") !== -1 ||
      locationHref.indexOf("client3.rekzyl.com") !== -1 ||
      locationHref.indexOf("localhost") !== -1
    ) {
      siteAttribute = "oapps-test";
    }
    window.Chargebee.init({ site: siteAttribute });
  }
  const items = [
    {
      key: "1",
      label: <span className="FAQuestion hs-fs-16"> Does HelloSend extension support two-way communication?</span>,
      children: (
        <p>
          Yes, our extension supports two-way messaging, enabling interactive conversations with customers. Users can receive and respond to SMS
          / WhatsApp replies directly from the CRM system.
        </p>
      ),
      style: panelStyle,
    },
    {
      key: "2",
      label: <span className="FAQuestion hs-fs-16">How HelloSend extension help with lead conversion?</span>,
      children: (
        <p>
          By utilizing Campaigns, our HelloSend extension can nurture them by sending personalized messages, driving engagement and guiding
          prospects through the sales funnel.
        </p>
      ),
      style: panelStyle,
    },
    {
      key: "3",
      label: <span className="FAQuestion hs-fs-16">Can HelloSend extension automate communication processes?</span>,
      children: (
        <p>
          Yes, our HelloSend extension allows for automated communication, such as sending automated notifications, reminders, or follow-ups
          based on CRM triggers or events
        </p>
      ),
      style: panelStyle,
    },
    {
      key: "4",
      label: <span className="FAQuestion hs-fs-16"> What features are available during the free trial?</span>,
      children: (
        <p>
          During the free trial, you’ll have access to the complete range of features our app offers. Explore and evaluate its capabilities to
          make an informed decision.
        </p>
      ),
      style: panelStyle,
    },
    // {
    //   key: "5",
    //   label: <span className="FAQuestion hs-fs-18">How are SMS messages billed?</span>,
    //   children: (
    //     <p>
    //       With our SMS extension, we employ a subscription-based billing model that eliminates per SMS charges. Instead, you can choose from
    //       monthly or yearly subscription plans that include a specified number of SMS messages within the subscription cost. This approach
    //       ensures cost predictability and removes the need to pay for individual messages.
    //     </p>
    //   ),
    //   style: panelStyle,
    // },
    {
      key: "6",
      label: <span className="FAQuestion hs-fs-16">What’s the difference between annual and monthly billing?</span>,
      children: (
        <p>
          Monthly billing charges occur on the same day each month, while annual billing involves a single payment for the entire year, with the
          added benefit of a discounted price.
        </p>
      ),
      style: panelStyle,
    },
    {
      key: "7",
      label: <span className="FAQuestion hs-fs-16">Are there any additional costs to consider?​</span>,
      children: <p>There are no additional costs apart from the subscription fee.</p>,
      style: panelStyle,
    },
    {
      key: "8",
      label: <span className="FAQuestion hs-fs-16">How can I contact customer support?</span>,
      children: (
        <p>
          For assistance, please email us at{" "}
          <a href="mailto:assist@hellosend.com" style={{ textDecoration: "none" }}>
            assist@hellosend.com
          </a>
          , and our team will promptly respond to your queries.
        </p>
      ),
      style: panelStyle,
    },
    // {
    //   key: "9",
    //   label: <span className="FAQuestion hs-fs-18">How is the refund processed for yearly subscriptions?</span>,
    //   children: (
    //     <p>If a yearly subscription is cancelled midway, the refund amount will be calculated based on the monthly subscription cost.</p>
    //   ),
    //   style: panelStyle,
    // },
    {
      key: "10",
      label: <span className="FAQuestion hs-fs-16">What are your phone support hours?</span>,
      children: <p>We are available from IST 10 am to IST 12 am for phone support.</p>,
      style: panelStyle,
    },
    {
      key: "11",
      label: <span className="FAQuestion hs-fs-16"> What is this subscription for?</span>,
      children: (
        <p>
          This subscription provides seamless integration with CRM services, allowing you to send and receive messages directly within your CRM
          system
        </p>
      ),
      style: panelStyle,
    },
    {
      key: "12",
      label: <span className="FAQuestion hs-fs-16"> Do I get a number with the subscription plans?</span>,
      children: (
        <p>
          No, you can purchase a number at additional cost and you need to purchase credits for sending SMS. Alternatively, you can bring your
          own number from Twilio or RingCentral{" "}
        </p>
      ),
      style: panelStyle,
    },
  ];

  function handleClick() {
    const urlParams = new URLSearchParams(window.location.search);
    var osyncId = urlParams.get("osyncId");
    var leftServiceId = urlParams.get("leftServiceId");
    var leftServiceName = urlParams.get("service");
    var hash = urlParams.get("hash");
    var appProperties = {};
    appProperties.hash = hash;

    if (window.Chargebee !== undefined && window.Chargebee !== null) {
      window.Chargebee.registerAgain();
    }
    console.log("leftServiceId>>>>>", leftServiceId);
    const cbInstance = window.Chargebee.getInstance();
    const cart = cbInstance.getCart();
    cart.setCustomer({
      cf_oapps_id: osyncId,
      cf_leftserviceid: leftServiceId,
      cf_accounttype: "Omni",
      cf_servicename: leftServiceName,
    });

    cbInstance.setCheckoutCallbacks(function (cart) {
      // you can define a custom callbacks based on cart object
      return {
        loaded: function () {},
        close: function () {
          handleRefresh();
          isSuccessCheckout ? setSuccessModal(true) : console.log("failure");
        },

        success: function (hostedPageId) {
          setIsSuccessCheckout(true);
          if (hostedPageId !== null && hostedPageId !== "") {
            var url = `chargebee/license/add?hostedPageId=${hostedPageId}`;
            fetchData(url, "POST", null, appProperties).then((response) => {});
            handleRefresh();
          }
        },
        step: function (value) {
          if (value === "thankyou_screen") {
            isSuccessCheckout = true;
          }
        },
      };
    });
  }

  useEffect(() => {
    const redirectSendSMSFromModule = urlParams.get("serviceOrigin");
    const osyncId = urlParams.get("osyncId");
    setDomainUrl(redirectSendSMSFromModule);

    var productId = urlParamsObj.get("productId");
    var groupName = urlParamsObj.get("groupName");
    if (productId !== undefined && groupName !== undefined) {
      let targetUrl = `omni/license/${productId}?groupName=${groupName}&osyncId=${osyncId}`;
      fetchData(targetUrl, "GET", null, null).then((response) => {
        if (response) {
          response = JSON.parse(response).data;
          var defaultsData = response.defaults;
          const defaultsAscending = [...defaultsData].sort((a, b) => a.featureNumber - b.featureNumber);
          setDefaults(defaultsAscending);
          var featuresData = response.features;
          setFeature(featuresData);
          var plans = response.plans;
          setPlan(plans);
          if (window.Chargebee !== undefined && window.Chargebee !== null) {
            window.Chargebee.registerAgain();
          }
        }
      });
    }
  }, []);
  const icon = <div className="actionIconsSprite pricingFeatureIcon cursor-default"></div>;
  const comingSoonIcon = <Tooltip className="actionIconsSprite pricingFeatureComingSoonIcon cursor-default" title="Coming soon"></Tooltip>;

  const dataSource = [
    { key: "0", feature: "", monthly: "Monthly Plan", yearly: "Yearly Plan" },
    { key: "1", feature: "SMS Channel", monthly: icon, yearly: icon },
    { key: "2", feature: "WhatsApp Channel", monthly: icon, yearly: icon },
    { key: "4", feature: "One-on-One messaging", monthly: icon, yearly: icon },
    { key: "5", feature: "SMS / WhatsApp Campaigns", monthly: icon, yearly: icon },
    { key: "6", feature: "Automated Messaging", monthly: icon, yearly: icon },
    { key: "7", feature: "Templates for canned responses", monthly: icon, yearly: icon },
    { key: "8", feature: "Keyword Management", monthly: comingSoonIcon, yearly: comingSoonIcon },
    { key: "9", feature: "Messaging Analytics", monthly: icon, yearly: icon },
    { key: "10", feature: "Inbox", monthly: icon, yearly: icon },
    { key: "12", feature: "Priority Support", monthly: "Standard", yearly: "Faster response time" },
    { key: "13", feature: "Call Assistance", monthly: "No", yearly: "Yes*" },
    { key: "14", feature: "Onboarding Assistance", monthly: "Chargeable", yearly: "Free" },
    { key: "15", feature: "Loyalty Discounts", monthly: "No", yearly: "Yes (10% off)" },
  ];

  const FAQItem = ({ item, isExpanded, onToggle }) => {
    return (
      <div className={`faq-item mb-4 faq-item ${isExpanded ? "expand-outline" : ""}`}>
        <div className="hs-w-100">
          <Row>
            <Col span={20} className="mt-3">
              <Row>
                <div className="hs-fs-18 hs-fw-500">{item.label}</div>
              </Row>
              <Row>{isExpanded && <div className="me-5 pe-5 mb-0 pb-0 hs-color-dark-grey faq-item-body">{item.children}</div>}</Row>
            </Col>
            <Col
              span={2}
              className={`me-5 d-flex align-items-top hs-fs-24 cursor-pointer ${
                isExpanded ? "actionIconsSprite pricingExpandedTrue" : "actionIconsSprite pricingExpandedFalse"
              }`}
              onClick={onToggle}
            ></Col>
          </Row>
        </div>
      </div>
    );
  };
  const handleToggle = (key) => {
    setExpandedKey(expandedKey === key ? null : key);
  };

  return (
    <Layout>
      <Header className=" pricingHeader bg-white hs-w-100">
        <div className=" actionIconsSprite helloSendLogoWithText w-100 h-100  mt-2" alt="OAppS"></div>
        {/* <hr /> */}
      </Header>
      <Content className="hs-bg-white ">
        <Row className="bg-white pricingRow mb-5 pb-5 d-flex justify-content-center pricingPageHomeBackground">
          <Col>
            <Row>
              <Col span={24} xl={24} md={22} xs={24} className="d-flex justify-content-center">
                <h1 className="pt-3 mt-5 hs-fs-40 hs-fw-700 pricingHeadingLevel">Streamlined Pricing for Seamless Business</h1>
              </Col>
            </Row>
            <Row className="pt-3 d-flex justify-content-center hs-fw-400 hs-fs-18">
              <Col span={15}>
                <div className="certified pricingHeadingLevel2">
                  HelloSend provides budget-friendly pricing that enhances business efficiency.
                </div>
              </Col>
            </Row>
            <Row className="mt-5 d-flex justify-content-center">
              <Col span={10} className="d-flex justify-content-center monthlyPricingCard">
                <div className="hs-w-70 ms-5 pt-1 ps-3">
                  <h5 className="mt-4 mb-4 hs-fw-700 monthlyPlanLevel5">Monthly Plan</h5>
                  <p className="display-4 pb-2 hs-fs-32 hs-fw-700 hs-mb-0">
                    <span className="hs-fw-500 hs-fs-16">$</span>
                    15 <span className="hs-fs-12 hs-fw-400">/ user / month</span>
                  </p>
                  <p className="hs-fw-400 hs-fs-12">Minimum 3 users billed</p>
                  <Button
                    data-cb-type="checkout"
                    data-cb-item-0="HelloSend-Messaging-USD-Monthly"
                    data-cb-item-0-quantity="1"
                    onMouseEnter={handleClick}
                    value="large"
                    className="mb-4 mt-2 hs-fs-16 hs-fw-700 hs-h-40 hs-w-100 monthlyPlanGetStarted"
                  >
                    Get started
                  </Button>

                  <h6 className="hs-fw-700">Features</h6>
                  <ul className="list-unstyled">
                    {["One-on-One messaging", "Campaigns and Automation", "Assistance: Email Support"].map((feature, index) => (
                      <li key={index} className="mt-4 ms-3 d-flex align-items-center hs-fw-500">
                        <div className="actionIconsSprite pricingFeatureIcon"></div>
                        <span className="ms-2">{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
              <Col span={10} className="d-flex justify-content-start ps-4 yearlyPricingCard">
                <Card className="yearlyPlanCard hs-w-85" bodyStyle={{ padding: "24px", paddingTop: 16 }} headStyle={{ borderBottom: "none" }}>
                  <div>
                    <Row className="me-0 pe-0 hs-w-100">
                      <Col span={22}>
                        <Title level={4} className="mb-3 pb-1 d-flex align-items-center">
                          Yearly Plan{" "}
                          <Text type="secondary" className="hs-fw-700 hs-fs-12 ms-1 pt-1 yearlyPlanDiscount">
                            (Save 40%)
                          </Text>
                        </Title>
                      </Col>
                      <Col span={2}>
                        <img src={YearlyPricingTag} alt="YearlyPricingTag" className="ps-2 hs-w-200" />
                      </Col>
                    </Row>
                    <p className="display-4 pt-1 pb-2 hs-fw-700 hs-fs-32 hs-mb-0">
                      <span className="hs-fw-500 hs-fs-16">$</span>9 <span className="hs-fw-400 hs-fs-12">/ user / month</span>
                    </p>
                    <p className="pb-1 mb-1 hs-fw-400 hs-fs-12">Minimum 3 users billed</p>
                    <Button
                      value="large"
                      className="mb-4 d-flex align-items-center justify-content-center hs-fw-700 hs-fs-16 hs-h-40 yearlyCbItem hs-w-100"
                      data-cb-type="checkout"
                      data-cb-item-0="HelloSend-Messaging-USD-Yearly"
                      data-cb-item-0-quantity="1"
                      onMouseEnter={handleClick}
                    >
                      <span className="actionIconsSprite pricingGetStartedIcon"></span>
                      Get started
                    </Button>
                  </div>
                  <h6 className="pb-1 hs-fw-700">Features</h6>
                  <ul className="list-unstyled">
                    {["One-on-One messaging", "Campaigns and Automation", "Assistance: Email and Phone Support"].map((feature, index) => (
                      <li key={index} className="mt-4 ms-3 d-flex align-items-center hs-fw-500">
                        <div className="actionIconsSprite pricingFeatureIcon"></div>
                        <span className="ms-2">{feature}</span>
                      </li>
                    ))}
                  </ul>
                </Card>
              </Col>
            </Row>

            <Row
              id="exploreFeatures"
              className="d-flex justify-content-center align-items-center exploreFeatures cursor-pointer hs-w-100 hs-h-7"
              onClick={() => document.getElementById("seeWhat").scrollIntoView({ behavior: "smooth" })}
            >
              <Col>
                <Row className="hs-fw-500 hs-fs-16">Explore Features</Row>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="mb-5 pb-5 mt-4 pt-5 justify-content-center align-items-center">
          <Col xs={12} md={11} className="text-center">
            <Col>
              <h2 className="font-weight-bold hs-fw-500 hs-fs-32">“Over 500+ businesses have chosen us”</h2>
            </Col>
            <Col className="pt-2">
              <p className="text-muted hs-fw-500 hs-fs-18 hs-color-mediumDark">
                Certified solution partner: Empowering success through expert solutions
              </p>
            </Col>
            <Col className="pt-2">
              <p className="text-muted hs-fw-500 hs-fs-13 hs-color-mediumDark">
                Transparent pricing | 7 days cashback guarantee | Cancel anytime
              </p>
            </Col>

            <Row className="d-flex justify-content-center">
              <Col xs={24} md={24} xl={24} className="d-flex justify-content-center">
                <img className="pt-3 mt-3" src={require("../assets/images/PartnersLogo.png")} alt="OAppS" />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row id="seeWhat" justify="center" className="mb-4  pt-5 pb-4 seeWhat">
          <Col xs={24} md={20}>
            <h2 className="text-center pt-2">See what's included in each plan</h2>
          </Col>
        </Row>
        <Row justify="center" className="mt-4 pt-3 ms-4">
          <Col xs={24} md={22} xl={18}>
            <Row>
              {["feature", "monthly", "yearly"].map((col, idx) => (
                <Col key={col} span={idx === 2 ? 7 : 8} className={`comparison-${col}-column`}>
                  {dataSource.map((item) => (
                    <div key={item.key} className={`bottom-shadow comparison-${col}`}>
                      {item[col]}
                    </div>
                  ))}
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <Row justify="center" className="mt-5 mb-5">
          <Col xs={24} className="text-center mt hs-fs-12">
            <p>Subscriptions powered by Chargebee and Stripe</p>
          </Col>
        </Row>
      </Content>
      <Content className="hs-bg-GhostWhite">
        <Row className="pb-5 mt-5 FAQRow container d-flex justify-content-center">
          <Col className="pb-3" span={16} xs={20} md={24} xl={17}>
            <div className="FAQDiv">
              <div className="text-center mb-5">
                <p className="FAQTitle mb-0 hs-fw-500 hs-fs-32">Frequently Asked Questions</p>
              </div>

              <div>
                {items.map((item) => (
                  <FAQItem key={item.key} item={item} isExpanded={expandedKey === item.key} onToggle={() => handleToggle(item.key)} />
                ))}
              </div>
            </div>
          </Col>
        </Row>
        <Modal
          title={
            <Row>
              <div className="d-flex justify-content-center w-100">
                <span
                  className="d-flex justify-content-center actionIconsSprite successTickIcon"
                  style={{
                    width: 60,
                    height: 60,
                  }}
                ></span>
              </div>
            </Row>
          }
          centered
          width={425}
          closeIcon={<div className="actionIconsSprite modalCloseIcon" />}
          onOk={() => {}}
          className="successPricingModel"
          closable={false}
          open={successModal}
          footer={null}
        >
          <>
            <div className="mt-4 mb-2">
              <Row>
                <div className="d-flex justify-content-center w-100">
                  <span className="hs-fs-16 hs-fw-bolder hs-color-BlackRussian">You're All Set!</span>
                </div>
              </Row>
              <Row className="d-flex justify-content-center">
                <Col className="d-flex justify-content-center ms-3">
                  <div className="hs-fs-14">Your subscription is now active!</div>
                </Col>
              </Row>
              <Row className="d-flex justify-content-center">
                <Col className="d-flex justify-content-center ms-3">
                  <p className="hs-fs-12">Happy texting!</p>
                </Col>
              </Row>
            </div>

            <div className="d-flex justify-content-center justify-content-between">
              <Button className="hs-w-100 goToServicePricingBtn" onClick={handleClickDomainUrl}>
                Done
              </Button>
            </div>
          </>
        </Modal>
      </Content>
    </Layout>
  );
}
export default PricingPage;
