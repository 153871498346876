import { useEffect, useMemo, useRef, useState } from "react";
import Button from "../../components/custom/input/button/Button";
import useHttp from "../../hooks/useHttp";

export default function AuthorizeButton(props) {
  const { buttonName, loading, appProperties, doAuthorization, setIsLoadingAuthorizeBtn, onTokenSave } = props;
  const wabaId = useRef();
  const { fetchData } = useHttp();
  const loadedScriptRef = useRef();
  const getHandleMessageAuthEvent = useMemo(() => appProperties.providerCommonObj?.getHandleMessageAuthEvent?.(wabaId));

  const getRedirectUrlParamsForFinalAuth = (redirectUrl, code) => {
    if (!redirectUrl) return;

    const urlSearchParams = new URLSearchParams(redirectUrl);
    const state = urlSearchParams.get("state") ?? "";
    const currentWabaId = wabaId.current ?? "";
    const sendAsJson = true;
    return new URLSearchParams({ state: state, code: code, wabaId: currentWabaId, sendAsJson: sendAsJson });
  };

  const doFinalAuthorizationProcess = (response) => {
    if (response.authResponse && response.authResponse.code) {
      const code = response.authResponse.code;
      appProperties.setLoading(true);
      doAuthorization().then((redirectUrl) => {
        const redirectUrlParams = getRedirectUrlParamsForFinalAuth(redirectUrl, code);
        const targetUrl = "redirect?" + redirectUrlParams?.toString();
        fetchData(targetUrl, "GET", null, null).then((response) => {
          if (response) {
            const responseJson = JSON.parse(response);
            onTokenSave(responseJson.data.data.integId);
          }
        });
      });
    }
  };

  const initiateAuthorizationProcess = () => {
    setIsLoadingAuthorizeBtn(true);
    appProperties.providerCommonObj
      .getAuthorizeFunction?.(doFinalAuthorizationProcess, getHandleMessageAuthEvent)
      .then((authorizeFunction) => {
        if (authorizeFunction) {
          authorizeFunction();
        }
      })
      .catch(() => {
        console.error("debug_auth Error loading scripts for ", appProperties.providerCommonObj.serviceName);
      })
      .finally(() => {
        setIsLoadingAuthorizeBtn(false);
      });
  };

  const setScriptIsReady = () => {
    console.info("debug_auth script has loaded for ", appProperties.rightServiceName);
    setIsScriptReady(true);
  };

  const checkIsTheScriptIsLoaded = (url) => {
    loadedScriptRef.current = document.querySelector(`script[src="${url}"]`);
    if (loadedScriptRef.current) {
      loadedScriptRef.current.addEventListener("load", setScriptIsReady);
      return loadedScriptRef;
    }
  };

  const [isScriptReady, setIsScriptReady] = useState(checkIsTheScriptIsLoaded(appProperties.providerCommonObj.baseUrlForTheScript));

  useEffect(() => {
    appProperties.providerCommonObj
      .loadScripts?.()
      .then((baseUrlOfTheScipt) => {
        checkIsTheScriptIsLoaded(baseUrlOfTheScipt);
      })
      .catch(() => {
        console.error("debug_auth Error loading scripts for ", appProperties.providerCommonObj.serviceName);
      });

    return () => {
      appProperties.providerCommonObj.doUnloadAuthorizationProcess?.(getHandleMessageAuthEvent);
      loadedScriptRef.current.removeEventListener("load", setScriptIsReady);
    };
  }, []);

  return (
    <div className="hs-min-w-10-rem">
      <Button disabled={!isScriptReady} loading={loading} size="xl" onClick={initiateAuthorizationProcess}>
        {buttonName}
      </Button>
    </div>
  );
}
