import { Typography } from "antd";
import { useEffect, useState } from "react";

export default function Link({ baseUrl, children, appProperties, externalLink = false, textClasses = "" }) {
  const { Link } = Typography;
  const [urlParams, setUrlParams] = useState("");
  useEffect(() => {
    if (!externalLink) {
      appProperties?.controller?.getUrlParamsForRedirection(true, appProperties).then((urlParamsFromController) => {
        setUrlParams(urlParamsFromController);
      });
    }
  }, [appProperties]);

  return (
    <span className="mx-1 hs-d-if">
      <Link className="hs-link d-flex align-items-center gap-1" href={externalLink ? baseUrl : baseUrl + urlParams} target="_blank">
        <Text className={textClasses}>{children}</Text>
      </Link>
    </span>
  );
}

Link.Icon = Icon;

function Text({ children, className }) {
  return <span className={className}>{children}</span>;
}

function Icon({ icon }) {
  return <>{icon}</>;
}
