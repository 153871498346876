export let APP_URL = "https://api.oapps.xyz/rest";
export let APP_CLIENT_URL = "https://alpha.oapps.xyz/client";
export let APP_REACT_URL = "https://omessage.oapps.xyz";
export let COMPANY_NAME = "OAppS";
export let ASSIST_MAIL = "assist@oapps.xyz";
export let ZOHO_CRM_API_VARIABLE_NAME = "ringcentralbulksmsextensionforzohocrm__".trim();
export let Chargebee_PLAN_NAME = "HelloSend-Messaging-USD-Monthly";
export let Chargebee_CHARGE_NAME = "Gold-Credits-USD";
export const urlParams = new URLSearchParams(window.location.search);
export const localStorage = isBrowserSupportsLocalStorage() ? window.localStorage : {};
export const integId = "";
export const osyncId = "";
export const hash = "";
export const osyncUrl = "";
export const clientUrl = "";
export const isOmniAccount = urlParams.get("isOmniAccount");
export const rightServiceId = "";
export const leftServiceId = "";
export const serviceName = "";
export const ZOHODESK = {};
export const WHATSAPP_DISPLAY_NAME = "WhatsApp";
export const WHATSAPP_BETA_DISPLAY_NAME = WHATSAPP_DISPLAY_NAME + " (Beta)";
// export const API_VERSION = urlParams.get("version") === "2" ? "v2" : "v1";
export let API_VERSION = "v2";
if (window.location.pathname.includes("pricing")) {
  API_VERSION = "v1";
}
var locationHref = window.location.origin;

if (locationHref.indexOf("localhost") !== -1) {
  APP_URL = "http://localhost:7000";
  APP_CLIENT_URL = "http://localhost:3000/app";
  APP_REACT_URL = "http://localhost:3001";
  Chargebee_PLAN_NAME = "Gold-User-Based-USD-Monthly";
  Chargebee_CHARGE_NAME = "staging-gold-credits-USD";
  ZOHO_CRM_API_VARIABLE_NAME = "localsetupsms__".trim();
} else if (
  locationHref.indexOf("staging-osync-client-qa") !== -1 ||
  locationHref.indexOf("pre-alpha.oapps.xyz") !== -1 ||
  locationHref.indexOf("osync-api.oapps.xyz") !== -1
) {
  APP_URL = "https://pre-api.oapps.xyz/rest";
  APP_CLIENT_URL = "http://pre-alpha.oapps.xyz/client";
  APP_REACT_URL = "https://pre-omessage.oapps.xyz";
} else if (
  locationHref.indexOf("staging-client-osync-development-2") !== -1 ||
  locationHref.indexOf("staging-react-client") !== -1 ||
  locationHref.indexOf("mars.rekzyl.com") !== -1
) {
  APP_URL = "https://ganga.rekzyl.com";
  APP_CLIENT_URL = "http://mars.rekzyl.com/client";
} else if (locationHref.indexOf("staging-client-osync-development") !== -1 || locationHref.indexOf("mercury.rekzyl.com") !== -1) {
  APP_URL = "https://ganga.rekzyl.com";
  APP_CLIENT_URL = "http://mercury.rekzyl.com/client";
} else if (locationHref.indexOf("ngrok") !== -1) {
  APP_URL = "https://d870-2401-4900-1ce3-3f96-b0fa-2479-2a30-7982.ngrok-free.app";
  APP_CLIENT_URL = "https://6d07-2401-4900-1ce2-b663-ec67-3ce-8d4a-d03b.ngrok-free.app";
  APP_REACT_URL = "https://30bb-2401-4900-1ce3-3f96-b0fa-2479-2a30-7982.ngrok-free.app";
  Chargebee_PLAN_NAME = "Gold-USD-Monthly";
  Chargebee_CHARGE_NAME = "staging-gold-credits-USD";
} else if (locationHref.indexOf("client3.rekzyl.com") !== -1) {
  APP_URL = "https://server3.rekzyl.com";
  APP_CLIENT_URL = "https://client1.rekzyl.com/client";
  APP_REACT_URL = "https://client3.rekzyl.com";
  Chargebee_PLAN_NAME = "Gold-User-Based-USD-Monthly";
  Chargebee_CHARGE_NAME = "staging-gold-credits-USD";
  ZOHO_CRM_API_VARIABLE_NAME = "localsetupsms__".trim();
} else if (locationHref.indexOf("client4.rekzyl.com") !== -1) {
  APP_URL = "https://server4.rekzyl.com";
  APP_CLIENT_URL = "https://client-qa.rekzyl.com/client";
  APP_REACT_URL = "https://client4.rekzyl.com";
  Chargebee_PLAN_NAME = "Gold-User-Based-USD-Monthly";
  Chargebee_CHARGE_NAME = "staging-gold-credits-USD";
  ZOHO_CRM_API_VARIABLE_NAME = "stagingsetupqakiruba__".trim();
} else if (locationHref.indexOf("client2.rekzyl.com") !== -1) {
  APP_URL = "https://server2.rekzyl.com";
  APP_CLIENT_URL = "https://client1.rekzyl.com/client";
  APP_REACT_URL = "https://client2.rekzyl.com";
  Chargebee_PLAN_NAME = "Gold-User-Based-USD-Monthly";
  Chargebee_CHARGE_NAME = "staging-gold-credits-USD";
  ZOHO_CRM_API_VARIABLE_NAME = "stagingsetupqashanthi__".trim();
} else if (locationHref.indexOf("client5.rekzyl.com") !== -1) {
  APP_URL = "https://server5.rekzyl.com";
  APP_CLIENT_URL = "https://client-qa.rekzyl.com/client";
  APP_REACT_URL = "https://client5.rekzyl.com";
} else if (locationHref.indexOf("pre-omessage.oapps.xyz") !== -1) {
  APP_URL = "https://pre-api.oapps.xyz/rest";
  APP_CLIENT_URL = "https://pre-alpha.oapps.xyz/client";
  APP_REACT_URL = "https://pre-omessage.oapps.xyz";
  Chargebee_PLAN_NAME = "Gold-USD-Monthly";
  Chargebee_CHARGE_NAME = "staging-gold-credits-USD";
} else if (locationHref.indexOf("client2.rekzyl.com") !== -1) {
  APP_URL = "https://server2.rekzyl.com";
  APP_CLIENT_URL = "https://client1.rekzyl.com/client";
  APP_REACT_URL = "https://client2.rekzyl.com";
} else if (locationHref.indexOf("client6.rekzyl.com") !== -1) {
  APP_URL = "https://server6.rekzyl.com";
  APP_CLIENT_URL = "https://client1.rekzyl.com/client";
  APP_REACT_URL = "https://client6.rekzyl.com";
}

export const countries = [
  { label: "Afghanistan", value: "AF" },
  { label: "Albania", value: "AL" },
  { label: "Algeria", value: "DZ" },
  { label: "American Samoa", value: "AS" },
  { label: "Andorra", value: "AD" },
  { label: "Angola", value: "AO" },
  { label: "Anguilla", value: "AI" },
  { label: "Antarctica", value: "AQ" },
  { label: "Antigua & Barbuda", value: "AG" },
  { label: "Argentina", value: "AR" },
  { label: "Armenia", value: "AM" },
  { label: "Aruba", value: "AW" },
  { label: "Australia", value: "AU" },
  { label: "Austria", value: "AT" },
  { label: "Azerbaijan", value: "AZ" },
  { label: "Bahamas", value: "BS" },
  { label: "Bahrain", value: "BH" },
  { label: "Bangladesh", value: "BD" },
  { label: "Barbados", value: "BB" },
  { label: "Belarus", value: "BY" },
  { label: "Belgium", value: "BE" },
  { label: "Belize", value: "BZ" },
  { label: "Benin", value: "BJ" },
  { label: "Bermuda", value: "BM" },
  { label: "Bhutan", value: "BT" },
  { label: "Bolivia", value: "BO" },
  { label: "Bosnia & Herzegovina", value: "BA" },
  { label: "Botswana", value: "BW" },
  { label: "Bouvet Island", value: "BV" },
  { label: "Brazil", value: "BR" },
  { label: "British Indian Ocean Territory", value: "IO" },
  { label: "British Virgin Islands", value: "VG" },
  { label: "Brunei", value: "BN" },
  { label: "Bulgaria", value: "BG" },
  { label: "Burkina Faso", value: "BF" },
  { label: "Burundi", value: "BI" },
  { label: "Cambodia", value: "KH" },
  { label: "Cameroon", value: "CM" },
  { label: "Canada", value: "CA" },
  { label: "Cape Verde", value: "CV" },
  { label: "Caribbean Netherlands", value: "BQ" },
  { label: "Cayman Islands", value: "KY" },
  { label: "Central African Republic", value: "CF" },
  { label: "Chad", value: "TD" },
  { label: "Chile", value: "CL" },
  { label: "China", value: "CN" },
  { label: "Christmas Island", value: "CX" },
  { label: "Cocos (Keeling) Islands", value: "CC" },
  { label: "Colombia", value: "CO" },
  { label: "Comoros", value: "KM" },
  { label: "Congo - Brazzaville", value: "CG" },
  { label: "Congo - Kinshasa", value: "CD" },
  { label: "Cook Islands", value: "CK" },
  { label: "Costa Rica", value: "CR" },
  { label: "Croatia", value: "HR" },
  { label: "Cuba", value: "CU" },
  { label: "Curaçao", value: "CW" },
  { label: "Cyprus", value: "CY" },
  { label: "Czechia", value: "CZ" },
  { label: "Côte d\u2019Ivoire", value: "CI" },
  { label: "Denmark", value: "DK" },
  { label: "Djibouti", value: "DJ" },
  { label: "Dominica", value: "DM" },
  { label: "Dominican Republic", value: "DO" },
  { label: "Ecuador", value: "EC" },
  { label: "Egypt", value: "EG" },
  { label: "El Salvador", value: "SV" },
  { label: "Equatorial Guinea", value: "GQ" },
  { label: "Eritrea", value: "ER" },
  { label: "Estonia", value: "EE" },
  { label: "Eswatini", value: "SZ" },
  { label: "Ethiopia", value: "ET" },
  { label: "Falkland Islands", value: "FK" },
  { label: "Faroe Islands", value: "FO" },
  { label: "Fiji", value: "FJ" },
  { label: "Finland", value: "FI" },
  { label: "France", value: "FR" },
  { label: "French Guiana", value: "GF" },
  { label: "French Polynesia", value: "PF" },
  { label: "French Southern Territories", value: "TF" },
  { label: "Gabon", value: "GA" },
  { label: "Gambia", value: "GM" },
  { label: "Georgia", value: "GE" },
  { label: "Germany", value: "DE" },
  { label: "Ghana", value: "GH" },
  { label: "Gibraltar", value: "GI" },
  { label: "Greece", value: "GR" },
  { label: "Greenland", value: "GL" },
  { label: "Grenada", value: "GD" },
  { label: "Guadeloupe", value: "GP" },
  { label: "Guam", value: "GU" },
  { label: "Guatemala", value: "GT" },
  { label: "Guernsey", value: "GG" },
  { label: "Guinea", value: "GN" },
  { label: "Guinea-Bissau", value: "GW" },
  { label: "Guyana", value: "GY" },
  { label: "Haiti", value: "HT" },
  { label: "Heard & McDonald Islands", value: "HM" },
  { label: "Honduras", value: "HN" },
  { label: "Hong Kong SAR China", value: "HK" },
  { label: "Hungary", value: "HU" },
  { label: "Iceland", value: "IS" },
  { label: "India", value: "IN" },
  { label: "Indonesia", value: "ID" },
  { label: "Iran", value: "IR" },
  { label: "Iraq", value: "IQ" },
  { label: "Ireland", value: "IE" },
  { label: "Isle of Man", value: "IM" },
  { label: "Israel", value: "IL" },
  { label: "Italy", value: "IT" },
  { label: "Jamaica", value: "JM" },
  { label: "Japan", value: "JP" },
  { label: "Jersey", value: "JE" },
  { label: "Jordan", value: "JO" },
  { label: "Kazakhstan", value: "KZ" },
  { label: "Kenya", value: "KE" },
  { label: "Kiribati", value: "KI" },
  { label: "Kuwait", value: "KW" },
  { label: "Kyrgyzstan", value: "KG" },
  { label: "Laos", value: "LA" },
  { label: "Latvia", value: "LV" },
  { label: "Lebanon", value: "LB" },
  { label: "Lesotho", value: "LS" },
  { label: "Liberia", value: "LR" },
  { label: "Libya", value: "LY" },
  { label: "Liechtenstein", value: "LI" },
  { label: "Lithuania", value: "LT" },
  { label: "Luxembourg", value: "LU" },
  { label: "Macao SAR China", value: "MO" },
  { label: "Madagascar", value: "MG" },
  { label: "Malawi", value: "MW" },
  { label: "Malaysia", value: "MY" },
  { label: "Maldives", value: "MV" },
  { label: "Mali", value: "ML" },
  { label: "Malta", value: "MT" },
  { label: "Marshall Islands", value: "MH" },
  { label: "Martinique", value: "MQ" },
  { label: "Mauritania", value: "MR" },
  { label: "Mauritius", value: "MU" },
  { label: "Mayotte", value: "YT" },
  { label: "Mexico", value: "MX" },
  { label: "Micronesia", value: "FM" },
  { label: "Moldova", value: "MD" },
  { label: "Monaco", value: "MC" },
  { label: "Mongolia", value: "MN" },
  { label: "Montenegro", value: "ME" },
  { label: "Montserrat", value: "MS" },
  { label: "Morocco", value: "MA" },
  { label: "Mozambique", value: "MZ" },
  { label: "Myanmar (Burma)", value: "MM" },
  { label: "Namibia", value: "NA" },
  { label: "Nauru", value: "NR" },
  { label: "Nepal", value: "NP" },
  { label: "Netherlands", value: "NL" },
  { label: "New Caledonia", value: "NC" },
  { label: "New Zealand", value: "NZ" },
  { label: "Nicaragua", value: "NI" },
  { label: "Niger", value: "NE" },
  { label: "Nigeria", value: "NG" },
  { label: "Niue", value: "NU" },
  { label: "Norfolk Island", value: "NF" },
  { label: "North Korea", value: "KP" },
  { label: "North Macedonia", value: "MK" },
  { label: "Northern Mariana Islands", value: "MP" },
  { label: "Norway", value: "NO" },
  { label: "Oman", value: "OM" },
  { label: "Pakistan", value: "PK" },
  { label: "Palau", value: "PW" },
  { label: "Palestinian Territories", value: "PS" },
  { label: "Panama", value: "PA" },
  { label: "Papua New Guinea", value: "PG" },
  { label: "Paraguay", value: "PY" },
  { label: "Peru", value: "PE" },
  { label: "Philippines", value: "PH" },
  { label: "Pitcairn Islands", value: "PN" },
  { label: "Poland", value: "PL" },
  { label: "Portugal", value: "PT" },
  { label: "Puerto Rico", value: "PR" },
  { label: "Qatar", value: "QA" },
  { label: "Romania", value: "RO" },
  { label: "Russia", value: "RU" },
  { label: "Rwanda", value: "RW" },
  { label: "Réunion", value: "RE" },
  { label: "Samoa", value: "WS" },
  { label: "San Marino", value: "SM" },
  { label: "Saudi Arabia", value: "SA" },
  { label: "Senegal", value: "SN" },
  { label: "Serbia", value: "RS" },
  { label: "Seychelles", value: "SC" },
  { label: "Sierra Leone", value: "SL" },
  { label: "Singapore", value: "SG" },
  { label: "Sint Maarten", value: "SX" },
  { label: "Slovakia", value: "SK" },
  { label: "Slovenia", value: "SI" },
  { label: "Solomon Islands", value: "SB" },
  { label: "Somalia", value: "SO" },
  { label: "South Africa", value: "ZA" },
  { label: "South Georgia & South Sandwich Islands", value: "GS" },
  { label: "South Korea", value: "KR" },
  { label: "South Sudan", value: "SS" },
  { label: "Spain", value: "ES" },
  { label: "Sri Lanka", value: "LK" },
  { label: "St. Barthélemy", value: "BL" },
  { label: "St. Helena", value: "SH" },
  { label: "St. Kitts & Nevis", value: "KN" },
  { label: "St. Lucia", value: "LC" },
  { label: "St. Martin", value: "MF" },
  { label: "St. Pierre & Miquelon", value: "PM" },
  { label: "St. Vincent & Grenadines", value: "VC" },
  { label: "Sudan", value: "SD" },
  { label: "Suriname", value: "SR" },
  { label: "Svalbard & Jan Mayen", value: "SJ" },
  { label: "Sweden", value: "SE" },
  { label: "Switzerland", value: "CH" },
  { label: "Syria", value: "SY" },
  { label: "São Tomé & Príncipe", value: "ST" },
  { label: "Taiwan", value: "TW" },
  { label: "Tajikistan", value: "TJ" },
  { label: "Tanzania", value: "TZ" },
  { label: "Thailand", value: "TH" },
  { label: "Timor-Leste", value: "TL" },
  { label: "Togo", value: "TG" },
  { label: "Tokelau", value: "TK" },
  { label: "Tonga", value: "TO" },
  { label: "Trinidad & Tobago", value: "TT" },
  { label: "Tunisia", value: "TN" },
  { label: "Turkey", value: "TR" },
  { label: "Turkmenistan", value: "TM" },
  { label: "Turks & Caicos Islands", value: "TC" },
  { label: "Tuvalu", value: "TV" },
  { label: "US Outlying Islands", value: "UM" },
  { label: "US Virgin Islands", value: "VI" },
  { label: "Uganda", value: "UG" },
  { label: "Ukraine", value: "UA" },
  { label: "United Arab Emirates", value: "AE" },
  { label: "United Kingdom", value: "GB" },
  { label: "United States", value: "US" },
  { label: "Uruguay", value: "UY" },
  { label: "Uzbekistan", value: "UZ" },
  { label: "Vanuatu", value: "VU" },
  { label: "Vatican City", value: "VA" },
  { label: "Venezuela", value: "VE" },
  { label: "Vietnam", value: "VN" },
  { label: "Wallis & Futuna", value: "WF" },
  { label: "Western Sahara", value: "EH" },
  { label: "Yemen", value: "YE" },
  { label: "Zambia", value: "ZM" },
  { label: "Zimbabwe", value: "ZW" },
  { label: "Åland Islands", value: "AX" },
];

export const languages = [
  {
    value: "Afrikaans",
    label: "Afrikaans",
  },
  {
    value: "Albanian",
    label: "Albanian",
  },
  {
    value: "Arabic",
    label: "Arabic",
  },
  {
    value: "Armenian",
    label: "Armenian",
  },
  {
    value: "Basque",
    label: "Basque",
  },
  {
    value: "Bengali",
    label: "Bengali",
  },
  {
    value: "Bulgarian",
    label: "Bulgarian",
  },
  {
    value: "Catalan",
    label: "Catalan",
  },
  {
    value: "Cambodian",
    label: "Cambodian",
  },
  {
    value: "Chinese (Mandarin)",
    label: "Chinese (Mandarin)",
  },
  {
    value: "Croatian",
    label: "Croatian",
  },
  {
    value: "Czech",
    label: "Czech",
  },
  {
    value: "Danish",
    label: "Danish",
  },
  {
    value: "Dutch",
    label: "Dutch",
  },
  {
    value: "English",
    label: "English",
  },
  {
    value: "Estonian",
    label: "Estonian",
  },
  {
    value: "Fiji",
    label: "Fiji",
  },
  {
    value: "Finnish",
    label: "Finnish",
  },
  {
    value: "French",
    label: "French",
  },
  {
    value: "Georgian",
    label: "Georgian",
  },
  {
    value: "German",
    label: "German",
  },
  {
    value: "Greek",
    label: "Greek",
  },
  {
    value: "Gujarati",
    label: "Gujarati",
  },
  {
    value: "Hebrew",
    label: "Hebrew",
  },
  {
    value: "Hindi",
    label: "Hindi",
  },
  {
    value: "Hungarian",
    label: "Hungarian",
  },
  {
    value: "Icelandic",
    label: "Icelandic",
  },
  {
    value: "Indonesian",
    label: "Indonesian",
  },
  {
    value: "Irish",
    label: "Irish",
  },
  {
    value: "Italian",
    label: "Italian",
  },
  {
    value: "Japanese",
    label: "Japanese",
  },
  {
    value: "Javanese",
    label: "Javanese",
  },
  {
    value: "Korean",
    label: "Korean",
  },
  {
    value: "Latin",
    label: "Latin",
  },
  {
    value: "Latvian",
    label: "Latvian",
  },
  {
    value: "Lithuanian",
    label: "Lithuanian",
  },
  {
    value: "Macedonian",
    label: "Macedonian",
  },
  {
    value: "Malay",
    label: "Malay",
  },
  {
    value: "Malayalam",
    label: "Malayalam",
  },
  {
    value: "Maltese",
    label: "Maltese",
  },
  {
    value: "Maori",
    label: "Maori",
  },
  {
    value: "Marathi",
    label: "Marathi",
  },
  {
    value: "Mongolian",
    label: "Mongolian",
  },
  {
    value: "Nepali",
    label: "Nepali",
  },
  {
    value: "Norwegian",
    label: "Norwegian",
  },
  {
    value: "Persian",
    label: "Persian",
  },
  {
    value: "Polish",
    label: "Polish",
  },
  {
    value: "Portuguese",
    label: "Portuguese",
  },
  {
    value: "Punjabi",
    label: "Punjabi",
  },
  {
    value: "Quechua",
    label: "Quechua",
  },
  {
    value: "Romanian",
    label: "Romanian",
  },
  {
    value: "Russian",
    label: "Russian",
  },
  {
    value: "Samoan",
    label: "Samoan",
  },
  {
    value: "Serbian",
    label: "Serbian",
  },
  {
    value: "Slovak",
    label: "Slovak",
  },
  {
    value: "Slovenian",
    label: "Slovenian",
  },
  {
    value: "Spanish",
    label: "Spanish",
  },
  {
    value: "Swahili",
    label: "Swahili",
  },
  {
    value: "Swedish ",
    label: "Swedish ",
  },
  {
    value: "Tamil",
    label: "Tamil",
  },
  {
    value: "Tatar",
    label: "Tatar",
  },
  {
    value: "Telugu",
    label: "Telugu",
  },
  {
    value: "Thai",
    label: "Thai",
  },
  {
    value: "Tibetan",
    label: "Tibetan",
  },
  {
    value: "Tonga",
    label: "Tonga",
  },
  {
    value: "Turkish",
    label: "Turkish",
  },
  {
    value: "Ukrainian",
    label: "Ukrainian",
  },
  {
    value: "Urdu",
    label: "Urdu",
  },
  {
    value: "Uzbek",
    label: "Uzbek",
  },
  {
    value: "Vietnamese",
    label: "Vietnamese",
  },
  {
    value: "Welsh",
    label: "Welsh",
  },
  {
    value: "Xhosa",
    label: "Xhosa",
  },
];

function isBrowserSupportsLocalStorage() {
  try {
    return "localStorage" in window && window["localStorage"] !== null;
  } catch (e) {
    return false;
  }
}

let ModalState = false;

function changeModalState(newValue) {
  ModalState = newValue;
}
export { ModalState, changeModalState };
