import { Button, Row, Col, notification, message } from "antd";
import "../../assets/css/notification.css";
import { FundsMonitor } from "./FundsMonitor";
import { APP_REACT_URL } from "../../constants/AppConstants";
import { InfoCircleOutlined } from "@ant-design/icons";
import { SUCCESS_ICON_ALERT } from "../../assets/svg/svgIcon";

export const successNotificationWithBtn = (notificationMessage, appProperties) => {
  var urlParams = new URLSearchParams(window.location.search);
  urlParams.set("sideBar", true);
  urlParams.set("topBar", true);
  var campaignLink = "/campaigns?" + urlParams;
  if (appProperties?.controller?.campaignLink) {
    campaignLink = `${APP_REACT_URL}/campaigns?leftServiceId=${appProperties.leftServiceId}&service=zohocrmredirect&domain=org${appProperties.companyId}&action=settings&userId=${appProperties.userId}&companyId=${appProperties.companyId}&onInstall=true&version=2`;
  }
  notification.destroy();
  notification.open({
    message: (
      <div className="h-100 d-flex justify-content-between">
        <div className="overflow-scroll" style={{ maxHeight: "70px" }}>
          {notificationMessage}
        </div>
        <div className="me-2">
          <Button
            style={{
              color: "#009EFD",
              background: "#C9EBFF",
              fontSize: 13,
            }}
            type="text"
            target="_blank"
            href={campaignLink}
          >
            View Report
          </Button>
        </div>
      </div>
    ),
    className: "successNotify",
    icon: <div className="staticIconsSprite successIcon" />,
    placement: "bottomLeft",
    duration: 0,
  });
};
export const successNotification = (notificationMessage) => {
  message.success(notificationMessage);
};
export const successNotifyWithDescription = (notificationMessage, messageDescription) => {
  message.success(messageDescription);
};

export const failureNotification = (errorMessage, errorDescription) => {
  notification.destroy();
  notification.open({
    message: (
      <div
        className="ms-2 pt-2"
        style={{
          fontSize: 13,
          paddingTop: 7,
          paddingLeft: "1rem",
          paddingRight: "12px",
        }}
      >
        {" "}
        {errorMessage}
      </div>
    ),
    description: (
      <div
        className="ms-2"
        style={{
          fontSize: 13,
          paddingTop: 7,
          paddingLeft: "1rem",
          paddingRight: "12px",
        }}
      >
        {" "}
        {errorDescription}
      </div>
    ),
    icon: (
      <div className="container h-100 pt-2">
        <div className="row h-100">
          <div className="col d-flex justify-content-center align-items-center">
            <FailureCommonIcon />
          </div>
        </div>
      </div>
    ),
    placement: "bottomLeft",
    duration: 5,
  });
};

export const failureNotificationWithDuration15Seconds = (errorMessage, errorDescription) => {
  // notification.destroy();
  notification.open({
    message: (
      <div
        className="ms-2 pt-2"
        style={{
          fontSize: 13,
          paddingTop: 7,
          paddingLeft: "1rem",
          paddingRight: "12px",
        }}
      >
        {" "}
        {errorMessage}
      </div>
    ),
    description: (
      <div
        className="ms-2"
        style={{
          fontSize: 13,
          paddingTop: 7,
          paddingLeft: "1rem",
          paddingRight: "12px",
        }}
      >
        {" "}
        {errorDescription}
      </div>
    ),
    icon: (
      <div className="container h-100 pt-2">
        <div className="row h-100">
          <div className="col d-flex justify-content-center align-items-center">
            <FailureCommonIcon />
          </div>
        </div>
      </div>
    ),
    placement: "bottomLeft",
    duration: 15,
  });
};

export const failureNotificationWithBtn = (title, errorMessage, button, buttonLink) => {
  console.log(button, "button>>>>>>", buttonLink);
  notification.destroy();
  notification.open({
    message: <div className="ms-2"> {title}</div>,
    description: <div className="ms-2">{errorMessage}</div>,
    style: { width: 340 },
    className: "pe-2",
    icon: (
      <div className="container h-100">
        <div className="row h-100">
          <div className="col d-flex justify-content-center align-items-center">
            <FailureCommonIcon />
          </div>
        </div>
      </div>
    ),
    btn: (
      <Button
        className="hs-bg-violet hs-hover-none d-flex align-items-center px-4 text-white hs-text-decoration-none hs-border-5"
        target="_blank"
        href={buttonLink}
      >
        {button}
      </Button>
    ),
    placement: "bottomLeft",
    duration: 0,
  });
};
export const failurePricingNotificationWithBtn = (title, errorMessage, button, appProperties, stripeLink) => {
  const registerChargeBee = (e) => {
    if (stripeLink) {
      window.open(stripeLink);
    } else {
      window.Chargebee?.registerAgain();
      console.log("click", e);
    }
  };

  const openNotification = (btnContent) => {
    notification.destroy();
    notification.open({
      message: <div className="ms-2">{title}</div>,
      description: <div className="pe-3 ps-2">{errorMessage}</div>,
      style: { width: 440 },
      className: "pe-2 ps-2",
      icon: (
        <div className="container h-100">
          <div className="row h-100">
            <div className="col d-flex justify-content-center align-items-center">
              <FailureCommonIcon />
            </div>
          </div>
        </div>
      ),
      btn: btnContent,
      placement: "bottomLeft",
      duration: 0,
    });
  };

  if (appProperties?.licenseObj?.licenseDetails?.licenseStatus === 4 || appProperties?.licenseObj?.licenseDetails?.licenseStatus === 5) {
    appProperties.controller.pricingLink(appProperties).then((resp) => {
      openNotification(
        <Button
          className="hs-bg-violet hs-hover-none d-flex align-items-center px-4 text-white hs-text-decoration-none hs-border-5 me-3"
          target="_blank"
          href={resp}
        >
          {button}
        </Button>
      );
    });
  } else {
    const buttonProps = stripeLink ? {} : { onMouseEnter: registerChargeBee };
    openNotification(
      <Button
        {...buttonProps}
        data-cb-type="portal"
        onClick={registerChargeBee}
        className="hs-bg-violet hs-hover-none d-flex align-items-center px-4 text-white hs-text-decoration-none hs-border-5 me-3"
      >
        {button}
      </Button>
    );
  }
};

export const failureNotificationWithLink = (errorMessage, helpMsg, mailId) => {
  notification.destroy();
  notification.open({
    message: (
      <div>
        <div>
          <Row style={{ paddingRight: 10 }}>
            <div style={{ paddingTop: 7, paddingLeft: "1rem" }}>{errorMessage}</div>
            <div style={{ paddingTop: 7, paddingLeft: "1rem" }}>
              <a
                style={{
                  color: "#FF507A",
                  padding: 0,
                  fontSize: 13,
                }}
                href={mailId}
              >
                {helpMsg}
              </a>
            </div>
          </Row>
        </div>
      </div>
    ),
    icon: (
      <div className="container h-100">
        <div className="row h-100">
          <div className="col d-flex justify-content-center align-items-center">
            <FailureCommonIcon />
          </div>
        </div>
      </div>
    ),
    placement: "bottomLeft",
    duration: 5,
  });
};

export const infoNotificationWithLink = (infoMessage, helpMsg, mailId) => {
  notification.destroy();
  notification.open({
    message: (
      <div>
        <div>
          <Row style={{ paddingRight: 10 }}>
            <div style={{ paddingTop: 4, paddingLeft: "1rem" }}>{infoMessage}</div>
            <div style={{ paddingTop: 7, paddingLeft: "1rem" }}>
              <a
                style={{
                  color: "#FF507A",
                  padding: 0,
                  fontSize: 13,
                }}
                href={mailId}
              >
                {helpMsg}
              </a>
            </div>
          </Row>
        </div>
      </div>
    ),
    icon: (
      <div className="container h-100">
        <div className="row h-100">
          <div className="col d-flex justify-content-center align-items-center text-warning mt-2">
            <InfoCircleOutlined />
          </div>
        </div>
      </div>
    ),
    placement: "bottomLeft",
    duration: 20,
  });
};

export const successNotificationViewReport = (infoMessage, helpMsg, mailId) => {
  notification.destroy();
  notification.open({
    message: (
      <div>
        <div>
          <Row style={{ paddingRight: 10 }}>
            <Col span={24} style={{ paddingTop: 7 }}>
              {infoMessage}
            </Col>
          </Row>
        </div>
      </div>
    ),
    icon: (
      <div className="container h-100 pt-2">
        <div className="row h-100">
          <div className="col d-flex justify-content-center align-items-center">
            <SUCCESS_ICON_ALERT />
          </div>
        </div>
      </div>
    ),
    placement: "bottomLeft",
    duration: 3,
  });
};

export const failureNotificationCredits = (errorMessage) => {
  notification.destroy();
  notification.open({
    message: (
      <div>
        <div>
          <Row style={{ paddingRight: 10 }}>
            <Col span={24} style={{ paddingTop: 7 }}>
              {errorMessage}
            </Col>
            <Col span={2} offset={1} style={{ paddingTop: 3 }}>
              <div>
                <FundsMonitor popOver={false} />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    ),
    icon: (
      <div className="container h-100 pt-2">
        <div className="row h-100">
          <div className="col d-flex justify-content-center align-items-center">
            <FailureCommonIcon />
          </div>
        </div>
      </div>
    ),
    placement: "bottomLeft",
    duration: 3,
  });
};

const FailureCommonIcon = () => {
  return (
    <div>
      <span
        role="img"
        aria-label="close-circle"
        className="anticon anticon-close-circle ant-notification-notice-icon ant-notification-notice-icon-error"
      >
        <svg
          fillRule="evenodd"
          viewBox="64 64 896 896"
          focusable="false"
          data-icon="close-circle"
          width="1em"
          height="1em"
          fill="currentColor"
          aria-hidden="true"
        >
          <path d="M512 64c247.4 0 448 200.6 448 448S759.4 960 512 960 64 759.4 64 512 264.6 64 512 64zm127.98 274.82h-.04l-.08.06L512 466.75 384.14 338.88c-.04-.05-.06-.06-.08-.06a.12.12 0 00-.07 0c-.03 0-.05.01-.09.05l-45.02 45.02a.2.2 0 00-.05.09.12.12 0 000 .07v.02a.27.27 0 00.06.06L466.75 512 338.88 639.86c-.05.04-.06.06-.06.08a.12.12 0 000 .07c0 .03.01.05.05.09l45.02 45.02a.2.2 0 00.09.05.12.12 0 00.07 0c.02 0 .04-.01.08-.05L512 557.25l127.86 127.87c.04.04.06.05.08.05a.12.12 0 00.07 0c.03 0 .05-.01.09-.05l45.02-45.02a.2.2 0 00.05-.09.12.12 0 000-.07v-.02a.27.27 0 00-.05-.06L557.25 512l127.87-127.86c.04-.04.05-.06.05-.08a.12.12 0 000-.07c0-.03-.01-.05-.05-.09l-45.02-45.02a.2.2 0 00-.09-.05.12.12 0 00-.07 0z"></path>
        </svg>
      </span>
    </div>
  );
};
