import { Checkbox } from "antd";

const CheckBoxField = ({ parentKey, field, settingValue, setErrorObj }) => {
  return (
    <>
      <div className="d-flex align-items-center">
        <div className="mb-0" key={field.key} name={field.key}>
          <Checkbox
            onChange={(e) => {
              field?.onchange(parentKey, field.key, e.target.checked);
              if (e === false) {
                setErrorObj(null);
              }
            }}
            className="checkbox-settings hs-color-dark-grey "
            checked={settingValue?.[parentKey]?.[field?.key] || field?.defaultChecked}
            disabled={field?.disabled || false}
          >
            {field?.label}
          </Checkbox>
        </div>
      </div>
    </>
  );
};
export default CheckBoxField;
