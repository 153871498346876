import { useState } from "react";
import ReusableFileUploadModal from "./ReusableFileUploadModal";

const MediaUploadIconWrapper = (props) => {
  const { fileList, setFileList, integId, skipFilter, showMMS, className = "" } = props;
  const [isUploadModalVisible, setIsUploadModalVisible] = useState();
  return (
    <>
      <ReusableFileUploadModal
        visible={isUploadModalVisible}
        setVisible={setIsUploadModalVisible}
        setFileList={setFileList}
        fileList={fileList}
        source={"sendsms"}
        integId={integId}
        skipFilter={skipFilter}
      />
      {showMMS ? (
        <div
          className={`actionIconsSprite conversationPageMediaUploadIcon ${className}`}
          onClick={() => setIsUploadModalVisible(true)}
          style={{ cursor: "pointer" }}
        ></div>
      ) : (
        <div></div>
      )}
    </>
  );
};
export default MediaUploadIconWrapper;
